import React, { useRef, useEffect } from "react"
import { Formik, Form, Field } from "formik"
import styles from "./../../../scss/components/BackToLive/_StepForm.module.scss"
import * as Yup from "yup"
const SignupSchema = Yup.object().shape({
  fullname: Yup.string()
    .min(2, "Demasiado Corto!")
    .max(50, "Muy largo!")
    .required("Este campo es obligatorio"),
  email: Yup.string()
    .email("Mail invalido")
    .required("Este campo es obligatorio"),
  province: Yup.string().required("Este campo es obligatorio"),
  instragram: Yup.string()
    .min(2, "Demasiado Corto!")
    .max(50, "Muy largo!")
    .optional("Campo opcional"),
})
export default function Step1({
  setPage,
  setValues,
  propValues,
  isSafari,
  isMobile,
}) {
  let showBtn
  showBtn = (
    <div
      className={
        isSafari && !isMobile ? styles.btnContainerSafari : styles.btnContainer
      }
    >
      <button className={styles.btnSubmit} type="submit">
        Siguiente
      </button>
    </div>
  )

  return (
    <Formik
      initialValues={{
        fullname: "",
        email: "",
        province: "",
        instagram: "",
      }}
      validationSchema={SignupSchema}
      onSubmit={values => {
        // same shape as initial values
        let newValues = { ...propValues, ...values }
        setValues(newValues)
        setPage(2)
      }}
    >
      {({ errors, touched }) => (
        <Form className={styles.formContainer}>
          <div className={styles.fieldContainer}>
            <Field
              className={styles.inputStyle}
              name="fullname"
              placeholder="Nombre y apellido"
            />
            {errors.fullname && touched.fullname ? (
              <div style={{ color: "#dd1a32" }}>{errors.fullname}</div>
            ) : null}
            <Field
              className={styles.inputStyle}
              name="email"
              type="email"
              placeholder="Mail"
            />
            {errors.email && touched.email ? (
              <div style={{ color: "#dd1a32" }}>{errors.email}</div>
            ) : null}
            <Field
              className={styles.inputStyle}
              name="province"
              placeholder="Provincia"
              component="select"
              id="province"
            >
              <option selected hidden>
                Selecciona una opción
              </option>
              <option value="CABA">CABA</option>
              <option value="BSAS">Buenos Aires</option>
              <option value="CA">Catamarca</option>
              <option value="CH">Chaco</option>
              <option value="CB">Córdoba</option>
              <option value="CR">Corrientes</option>
              <option value="ER">Entre Ríos</option>
              <option value="FO">Formosa</option>
              <option value="JY">Jujuy</option>
              <option value="LP">La Pampa</option>
              <option value="LR">La Rioja</option>
              <option value="MZ">Mendoza</option>
              <option value="MI">Misiones</option>
              <option value="NQN">Neuquén</option>
              <option value="RN">Río Negro</option>
              <option value="SA">Salta</option>
              <option value="SJ">San Juan</option>
              <option value="SL">San Luis</option>
              <option value="SC">Santa Cruz</option>
              <option value="SF">Santa Fe</option>
              <option value="SE">Santiago del Estero</option>
              <option value="TF">Tierra del Fuego</option>
              <option value="TU">Tucumán</option>
            </Field>
            {errors.province && touched.province ? (
              <div style={{ color: "#dd1a32" }}>{errors.province}</div>
            ) : null}
            <Field
              className={styles.inputStyle}
              name="instagram"
              placeholder="Instagram (opcional)"
            />
            {errors.instagram && touched.instagram ? (
              <div style={{ color: "#dd1a32" }}>{errors.instagram}</div>
            ) : null}
          </div>
          {showBtn}
        </Form>
      )}
    </Formik>
  )
}
