import React from "react"
import SEO from "./../seo"
import Footer from "./../Footer"
import styles from "./../../scss/pages/_backtolive.module.scss"

import logo from "./../../images/BackToLive/BudLogo2.svg"
import { Container } from "react-bootstrap"

export default function Success() {
  return (
    <div styles={{ overflow: "hidden" }}>
      <SEO title="Backtolive" />
      <Container fluid className="m-0 p-0">
        <section>
          <div className={styles.wrap}>
            <div>
              <img src={logo} className={styles.logo} />
            </div>
            <div className={styles.container}>
              <div className={styles.wrapText}>
                <h1 className={styles.infoTextOne}>YA ESTÁS PARTICIPANDO.</h1>
              </div>
              <div>
                <h1 className={styles.infoTextTwo}>
                  ¡LOS GANADORES RECIBIRÁN <br /> LAS ENTRADAS POR MAIL!
                </h1>
              </div>
            </div>
          </div>
        </section>
        <div className={styles.footer}>
          <Footer />
        </div>
      </Container>
    </div>
  )
}
