import React, { useEffect, useState } from "react"
import styles from "./../../scss/components/BackToLive/_StepForm.module.scss"
import { Formik, Form, Field } from "formik"
import BackIcon from "./../../images/BackToLive/backIcon.png"

import Step1 from "./Steps/Step1"
import Step2 from "./Steps/Step2"
import Step3 from "./Steps/Step3"
import Step4 from "./Steps/Step4"
import useDeviceDetect from "./hooks/useDevice"
export default function StepForm({ selectedProvince, selectedEvent }) {
  const [page, setPage] = useState(1)
  const [values, setValues] = useState({})
  const { isMobile, isSafari } = useDeviceDetect()
  let step
  if (page === 1) {
    step = (
      <Step1
        setValues={setValues}
        setPage={setPage}
        propValues={values}
        isMobile={isMobile}
        isSafari={isSafari}
      />
    )
  } else if (page === 2) {
    step = (
      <Step2
        setValues={setValues}
        setPage={setPage}
        propValues={values}
        isMobile={isMobile}
        isSafari={isSafari}
      />
    )
    // } else if (page === 3) {
    //   step = (
    //     <Step3
    //       setValues={setValues}
    //       setPage={setPage}
    //       propValues={values}
    //       isMobile={isMobile}
    //       isSafari={isSafari}
    //     />
    //   )
  } else {
    step = (
      <Step4
        setValues={setValues}
        propValues={values}
        selectedProvince={selectedProvince}
        selectedEvent={selectedEvent}
        isMobile={isMobile}
        isSafari={isSafari}
      />
    )
  }



  return (
    <>
      <div className={styles.stepFormContainer}>
        <div className={styles.formWrap}>
          <div className={styles.firstColumn}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h1 className={styles.pageTextOne}>{page}</h1>
              <h1 className={styles.pageTextTwo}>/3</h1>
            </div>
            {/* {!isMobile && page !== 1 ? (
              <div className={styles.backBtn}>
                <a
                  className={styles.btnWrapper}
                  onClick={() => setPage(page - 1)}
                >
                  <img src={BackIcon} style={{ objectFit: "contain" }} />
                  <h3>Volver</h3>
                </a>
              </div>
            ) : null} */}
            {page !== 1 ? (
              <div className={styles.backBtn}>
                <a
                  className={styles.btnWrapper}
                  onClick={() => setPage(page - 1)}
                >
                  <img
                    src={BackIcon}
                    style={
                      isMobile
                        ? { width: "12px", objectFit: "contain" }
                        : { objectFit: "contain" }
                    }
                  />
                  <h3>Volver</h3>
                </a>
              </div>
            ) : null}
          </div>
          <div className={styles.secondColumn}>{step}</div>
        </div>
      </div>
      {/*  */}
    </>
  )
}
